import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';

export default function useValidation() {
  const { $t } = useNuxtApp();
  const uiStore = useUiStore();
  const { isMobile } = storeToRefs(uiStore);
  const validateRequired = (value: unknown) => {
    if (!value || typeof value !== 'string') {
      return $t('form.invalid.required');
    }
    return true;
  };

  const validatePhoneInput = (value: unknown, shouldIgnore?: boolean) => {
    if (shouldIgnore) {
      return true;
    }
    if (!value || typeof value !== 'string') {
      return 'This field is required';
    }
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/im;
    if (!re?.test(value ?? 'a')) {
      return $t('form.invalid.phone');
    }
    return true;
  };

  const validateZipRequired = (value: unknown, shouldIgnore?: boolean) => {
    const globalContent = useGlobalContentStore();
    if (shouldIgnore) {
      return true;
    } else if (!value || typeof value !== 'string') {
      return $t('form.invalid.required');
    } else {
      if (!/^\d+$/.test(value)) {
        return $t('form.invalid.postal_code');
      }
      if ((globalContent.getLocale === 'sv' || globalContent.getLocale === 'fi') && value.length !== 5) {
        return $t('form.invalid.postal_code');
      }

      if ((globalContent.getLocale === 'dk' || globalContent.getLocale === 'no') && value.length !== 4) {
        return $t('form.invalid.postal_code');
      }

      if (globalContent.getLocale === 'uk' && (value.length <= 4 || value.length > 8)) {
        return $t('form.invalid.postal_code');
      }

      return true;
    }
  };

  const validateOrgNoRequired = (value: unknown, shouldIgnore?: boolean) => {
    const globalContent = useGlobalContentStore();
    if (shouldIgnore) {
      return true;
    } else if (!value || typeof value !== 'string') {
      return $t('form.invalid.required');
    }
    let minLength = 10;
    if (['dk', 'fi'].includes(globalContent.getLocale)) {
      minLength = 8;
    } else if (globalContent.getLocale === 'no') {
      minLength = 9;
    }
    if (value?.length < minLength) {
      return $t('form.invalid.min_chars', { min: minLength });
    }

    if (value?.length > 12) {
      return $t('form.invalid.max_chars', { max: 12 });
    }

    return true;
    
  };

  const startValidate = () => {
    setTimeout(()=> {
      const firstError = document.getElementsByClassName('input invalid')[0];
      if (firstError) {
        let pos = firstError.getBoundingClientRect().top + document.documentElement.scrollTop;
        if (isMobile) {
          pos-= 90;
        } else {
          pos-= 160;
        }
        scrollTo({
          top: pos,
          behavior: 'smooth' });
      }
    }, 120);
  };

  const validateEmail = (value: unknown) => {
    // if the field is empty
    if (!value || typeof value !== 'string') {
      return $t('form.invalid.required');
    }
    // if the field is not a valid email
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
      return $t('form.invalid.email');
    }
    // All is good
    return true;
  };

  const validatePassword = (value: unknown) => {
    if (!value || typeof value !== 'string' || value.length < 8) {
      return $t('form.invalid.min_chars', { min: '8' });
    }
    return true;
  };

  return {
    validatePhoneInput,
    validateRequired,
    validateZipRequired,
    startValidate,
    validateEmail,
    validateOrgNoRequired,
    validatePassword,
  };
}
